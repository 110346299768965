import { default as arpwLahbywquMeta } from "/home/runner/work/demos-space/demos-space/src/pages/ar.vue?macro=true";
import { default as indexh0S2jtrSDZMeta } from "/home/runner/work/demos-space/demos-space/src/pages/index.vue?macro=true";
export default [
  {
    name: arpwLahbywquMeta?.name ?? "ar",
    path: arpwLahbywquMeta?.path ?? "/ar",
    meta: arpwLahbywquMeta || {},
    alias: arpwLahbywquMeta?.alias || [],
    redirect: arpwLahbywquMeta?.redirect || undefined,
    component: () => import("/home/runner/work/demos-space/demos-space/src/pages/ar.vue").then(m => m.default || m)
  },
  {
    name: indexh0S2jtrSDZMeta?.name ?? "index",
    path: indexh0S2jtrSDZMeta?.path ?? "/",
    meta: indexh0S2jtrSDZMeta || {},
    alias: indexh0S2jtrSDZMeta?.alias || [],
    redirect: indexh0S2jtrSDZMeta?.redirect || undefined,
    component: () => import("/home/runner/work/demos-space/demos-space/src/pages/index.vue").then(m => m.default || m)
  }
]